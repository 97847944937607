import React from 'react'
import { Layout } from '@layout';
import { Grid, Flex, Col, Title, Text, Input, Button } from '@components';
import { AgregarProspecto } from '@admin/Prospectos';

function Agregar() {

	return (
		<Layout>
			<Grid  className="pv:2">

				<Title className="mb:2">Agregar prospecto</Title>
				<AgregarProspecto getValue={v => console.log(v)} />

				<Flex className="mt:2" justify="end">
					<Button square primary>Agregar prospecto</Button>
				</Flex>

			</Grid>
		</Layout>
	)
}

export default Agregar
